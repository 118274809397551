<template>
  <div>
    <h3>404</h3>
    <h3>
      <router-link to="/">回到首页</router-link>
    </h3>
  </div>
</template>
<script>
  export default {

  }
</script>
<style lang="scss" scoped>
  h3 {
    text-align: center;
  }
</style>
